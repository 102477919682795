<template>
    <div>
        marketing
    </div>
</template>

<script>
    export default {
        name: "Marketing"
    }
</script>

<style scoped>

</style>